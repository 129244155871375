import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Dash.module.css";
import SideBar from "../../../components/sideBar/SideBar";
import Dashboard from "../../../components/dashboard/DashBoard";
import TopServices from "../../../components/topServicos/TopServicos";
import Avalicao from "../../../components/avaliacaoCliente/AvaliacaoCliente";
import Kpi from "../../../components/kpi/kpi";
import Lucro from "../../../utils/assets/icones/icones.svg";
import Prdutos from "../../../utils/assets/icones/icones-1.svg";
import Metas from "../../../utils/assets/icones/icones-2.svg";
import Conexoes from "../../../utils/assets/icones/icones-3.svg";
import { useNavigate } from 'react-router-dom';
import { getId } from '../../../services/auth';
import { useParams, Link } from 'react-router-dom';
import api from "../../../api";

const Dash = () => {
  const navigate = useNavigate();
  const id = getId();

  console.log('id', id);

  const [empresaNome, setEmpresaNome] = useState("");
  useEffect(() => {
    const fetchEmpresaNome = async () => {
      api
        .get(
          `/empresas/filtrar/${id}`
        )
        .then((response) => {
          setEmpresaNome(response.data.nome);
        })
        .catch((error) => {
          console.error("Houve um erro ao buscar a empresa: " + error);
        });
    };
    fetchEmpresaNome();
  }
  , []);

  const [totalServicos, setTotalServicos] = useState(0);
  useEffect(() => {
    const fetchTotalServicos = async () => {
      api
        .get(
          `/servicos/totalServicos/${id}`
        )
        .then((response) => {
          setTotalServicos(response.data);
          console.log("Total de serviços: " + response.data);
        })
        .catch((error) => {
          console.error("Houve um erro ao buscar o total de serviços: " + error);
        });
    };
    fetchTotalServicos();
  }, []);

  const [totalPublicacoes, setTotalPublicacoes] = useState(0);
  useEffect(() => {
    const fetchTotalPublicacoes = async () => {
      api
        .get(
          `/publicacoes/totalPublicacoes/${id}`
        )
        .then((response) => {
          setTotalPublicacoes(response.data);
          console.log("Total de publicações: " + response.data);
        })
        .catch((error) => {
          console.error("Houve um erro ao buscar o total de publicações: " + error);
        });
    };
    fetchTotalPublicacoes();
  }, []);

  return (
    <>
      <main id={styles["containerDash"]}>
        <SideBar />
        <div className={styles["container"]}>
          <div className={styles["title"]}>
            <h2>Olá, {empresaNome}</h2>
          </div>
          <div className={styles["kpis-group"]}>
            <Kpi icon={Lucro} value={totalServicos} label="Serviçoes" />
            <Kpi icon={Prdutos} value={totalPublicacoes} label="Publicações" />
            <Kpi icon={Metas} value="22.150,00" label="Lucro do mês" />
            <Kpi icon={Conexoes} value="22.150,00" label="Lucro do mês" />
          </div>
          <div className={styles["dash"]}>
            <TopServices />
          </div>
        </div>
        <div className={styles["container2"]}>
          <Link to={`/empresa/perfil/${id}`} className={styles["linkPerfil"]}>
            <div className={styles['logo-perfil']}>
              <div className={styles["profile"]}>
                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDw223MyoqRAMcKva517vkE-FGoAfczHuRpg&s"} alt="Imagem da logo do perfil" />
              </div>
              <div className={styles["meu-perfil"]}>
                <h2>Meu Perfil</h2>
              </div>
            </div>
          </Link>
          <div className={styles["avaliacao"]}>
            <Avalicao />
          </div>
          <div className={styles["top"]}>
            <Link to="/">
              Chat
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dash;
