import React, { useState } from 'react';
import styles from './SideBar.module.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Logo from "../../utils/assets/logo.svg";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Link } from 'react-router-dom';
import { Navigate, useLocation, useNavigate } from "react-router-dom";


function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const Navigate = useNavigate();

  const handleSessionStorage = () => {
      sessionStorage.clear();
      Navigate('/');
    };
  

  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
      <div className={styles.logo} onClick={toggleSidebar}>
        <img src={Logo} alt="Work Door" />
      </div>
      <nav>
        <ul>
          <Link className={styles["linkBtn"]} to="/dash">
            <li id={styles["dashIcon"]} className={location.pathname === '/dash' ? styles.active : ''}>
              <DashboardIcon className={styles["corIcone"]}/>
              {isOpen && <a href="#">Dashboard</a>}
            </li>
          </Link>
          <Link className={styles["linkBtn"]} to="/empresa/configuracoes">
            <li className={location.pathname === '/empresa/configuracoes' ? styles.active : ''}>
              <SettingsIcon className={styles["corIcone"]}/>
              {isOpen && <a href="#">Configurações</a>}
            </li>
          </Link>
          <Link className={styles["linkBtn"]} to="/exportarServicos">
            <li className={styles["btnExportar"]}>
              <CloudDownloadIcon className={styles["corIcone"]}/>
              {isOpen && <a href="#">Relatório</a>}
            </li>
          </Link>
        </ul>
      </nav>
      <div className={isOpen ? styles.logout : styles.logoutSmall}>
        <button  onClick={handleSessionStorage}>
          <ExitToAppIcon className={styles["iconeLogout"]} />
          {isOpen && <span >Sair</span>}
        </button>
      </div>
    </aside>
  );
}
export default Sidebar;
