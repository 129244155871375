import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from "./Publicacoes.module.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "../../utils/assets/logo.svg";
library.add(faPaperclip, faPaperPlane);

function Publicacoes({ isOwner }) {
    const [name, setTitle] = useState('');
    const [descricao, setDesc] = useState('');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [message, setMessage] = useState(null);
    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState(5);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescChange = (event) => {
        setDesc(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setImageName(file ? file.name : null);
    };

    const fetchPosts = async () => {
        try {
            const response = await fetch('https://6605fc8ad92166b2e3c31978.mockapi.io/empresa');
            if (response.ok) {
                const data = await response.json();
                // Mapeia os dados para o formato esperado pelo componente
                const formattedPosts = data.map(post => ({
                    name: post.name,
                    descricao: post.descricao,
                    avatar: post.avatar
                }));
                setPosts(formattedPosts.reverse());
            } else {
                setMessage("Erro ao buscar publicações. Tente novamente.");
            }
        } catch (error) {
            setMessage("Erro ao buscar publicações. Tente novamente.");
        }
    };

    const handleSubmit = async () => {
        if (name.trim() === "" || descricao.trim() === "") {
            setMessage("Título e descrição não podem estar vazios.");
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('descricao', descricao);
        if (image) {
            formData.append('avatar', image);
        }

        try {
            const response = await fetch('https://6605fc8ad92166b2e3c31978.mockapi.io/empresa', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setTitle('');
                setDesc('');
                setImage(null);
                setImageName(null);
                setMessage("Publicação enviada com sucesso!");
                fetchPosts();
            } else {
                setMessage("Erro ao enviar a publicação. Tente novamente.");
            }
        } catch (error) {
            setMessage("Erro ao enviar a publicação. Tente novamente.");
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    const handleLoadMore = () => {
        setVisiblePosts(visiblePosts + 5);
    };

    return (
        <div id={style["container"]}>
            {isOwner && (
                <section id={style["areaPublicar"]}>
                    <div className={style["publication-input"]}>
                        <div className={style["logoPost"]}>
                            <img src={Logo} alt="" />
                        </div>

                        <div className={style["textarea-container"]}>
                            <input
                                type="text"
                                className={style["titleInput"]}
                                placeholder="Título da publicação"
                                value={name}
                                onChange={handleTitleChange}
                            />
                            <textarea
                                className={style["textArea"]}
                                placeholder="Escreva a descrição"
                                value={descricao}
                                onChange={handleDescChange}
                            />
                            <div className={style["actions"]}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="fileInput"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="fileInput" className={style["arquivoButton"]}>
                                    <FontAwesomeIcon icon="paperclip" className={style["attach-button"]} />
                                </label>
                                <button className={style["send-button"]} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon="paper-plane" />
                                </button>
                            </div>
                            {imageName && <p className={style["fileName"]}>{imageName}</p>}
                        </div>
                    </div>
                    {message && <p className={style["message"]}>{message}</p>}
                </section>
            )}
            <section id={style["publicacoes"]}>
                {posts.slice(0, visiblePosts).map((post, index) => (
                    <div key={index} className={style["post"]}>
                        <h3>{post.name}</h3>
                        <p>{post.descricao}</p>
                        <div className={style["areaImagem"]}>
                            {post.avatar && <img src={post.avatar} alt="Publicação" className={style["postImage"]} />}
                        </div>
                    </div>
                ))}
            </section>
            {posts.length > visiblePosts && (
                <div className={style["load-more"]}>
                    <button onClick={handleLoadMore}>Carregar Mais</button>
                </div>
            )}
        </div>
    );
}

Publicacoes.propTypes = {
    isOwner: PropTypes.bool.isRequired,
};

export default Publicacoes;
