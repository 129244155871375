import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const Mapa = ({ endereco }) => {
    const [center, setCenter] = useState({
        lat: -23.55052,
        lng: -46.633308
    });

    useEffect(() => {
        if (endereco && endereco.lat && endereco.lng) {
            console.log("Updated center coordinates:", { lat: endereco.lat, lng: endereco.lng });
            setCenter({
                lat: endereco.lat,
                lng: endereco.lng
            });
        }
    }, [endereco]);

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBNTsFnWD_LjXyRzgBK_u8IeC_NuegXoik"
    });

    const containerStyle = {
        width: '100%',
        height: '450px'
    };

    if (loadError) {
        console.error("Error loading Google Maps API", loadError);
        return <p>Error loading map</p>;
    }

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                >
                    {center && (
                        <Marker position={center} key={`${center.lat}-${center.lng}`} />
                    )}
                </GoogleMap>
            ) : <p>Carregando mapa...</p>}
        </div>
    );
};

export default Mapa;
