import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../../../components/navBar/NavBar';
import Logo from "../../../utils/assets/logo.svg";
import style from "./Perfil.module.css";
import InfoPerfilEmpresa from '../../../components/infoPerfilEmpresa/InfoPerfilEmpresa';
import Publicacoes from '../../../components/publicacoes/Publicacoes';
import Comentarios from '../../../components/comentarios/Comentarios';
import InputField from "../../../components/inputs/Inputs";
import api from "../../../api";
import { getUsuario, getId } from '../../../services/auth';

const Perfil = () => {
    const { id } = useParams();
    const [empresaInfo, setEmpresaInfo] = useState(null);
    const [error, setError] = useState(false);
    const [userType, setUserType] = useState('');
    const [userId, setUserId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State para controlar o modal de troca de logo

    useEffect(() => {
        const usuario = getUsuario();
        const id = getId();
        setUserType(usuario);
        setUserId(id);
        console.log('Tipo de usuário e id: ', id, usuario);
    }, []);

    const fetchEmpresaInfo = async () => {
        try {
            const response = await fetch(`https://6605fc8ad92166b2e3c31978.mockapi.io/empresa/${id}`);
            if (!response.ok) {
                throw new Error('Erro ao buscar informações da empresa');
            }
            const data = await response.json();
            setEmpresaInfo(data);
        } catch (error) {
            console.error('Erro ao buscar informações da empresa:', error);
            setError(true);
            toast.error('Erro ao buscar informações da empresa');
        }
    };

    useEffect(() => {
        if (id) {
            fetchEmpresaInfo();
        }
    }, [id]);

    const [selectedButton, setSelectedButton] = useState('Publicações');

    const [serviceCategory, setServiceCategory] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [serviceTitle, setServiceTitle] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (serviceCategory === '' || serviceDescription === '' || serviceTitle === '') {
            toast.error('Por favor, preencha todos os campos.');
            return;
        }

        const serviceData = {
            idEmpresa: userType === 'empresa' ? userId : null,
            categoriaServico: serviceCategory,
            descricaoServico: serviceDescription,
            nomeServico: serviceTitle,
            idCliente: userType === 'cliente' ? userId : null,
        };

        try {
            const response = await api.post('empresas/cadastraServico', serviceData);

            if (!response.ok) {
                throw new Error('Erro ao criar o serviço');
            }

            const responseData = await response.json();
            console.log(responseData);

            // Show success toast
            toast.success('Serviço criado com sucesso!');

            // Reset form fields and close modal after successful submission
            setServiceCategory('');
            setServiceDescription('');
            setServiceTitle('');
            setIsModalOpen(false);
        } catch (error) {
            console.error('Erro:', error);

            toast.error('Erro ao criar o serviço');
        }
    };

    return (
        <>
            <div id={style["perfilPrincipal"]}>
                <NavBar logoInicio={Logo} />
                <div id={style["areaTopo"]}>
                    {/* Adicione qualquer conteúdo adicional que desejar */}
                </div>
            </div>

            {empresaInfo && (
                <InfoPerfilEmpresa
                    logo={id === '1' ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDw223MyoqRAMcKva517vkE-FGoAfczHuRpg&s' : empresaInfo.logo}
                    nomeEmpresa={empresaInfo.name}
                    breveDesc={empresaInfo.breveDesc}
                    contratos={empresaInfo.contratos}
                    endereco={empresaInfo.endereco}
                    descricao={empresaInfo.descricao}
                    id={id} // Passando o id da empresa para o InfoPerfilEmpresa
                    setIsModalOpen={setIsModalOpen} // Passando a função para abrir/fechar o modal
                />
            )}

            <section id={style["areaBotoes"]}>
                <a onClick={() => setSelectedButton('Publicações')} href="#Publicacoes" className={`${style["linkBtn"]}  ${selectedButton === 'Publicações' ? style["selected"] : ''}`} >
                    <Link to="" >
                        <button>Publicações</button>
                    </Link>
                </a>

                <a onClick={() => setSelectedButton('Contratar')} className={`${style["linkBtn"]} ${selectedButton === 'Contratar' ? style["selected"] : ''}`}>
                    <Link to="/" >
                        <button>Contratar</button>
                    </Link>
                </a>

                <a className={`${style["linkBtn"]} ${selectedButton === 'Comentários' ? style["selected"] : ''}`} href="#Comentarios" onClick={() => setSelectedButton('Comentários')}>
                    <Link to="">
                        <button>Comentários</button>
                    </Link>
                </a>

                {userType === 'empresa' && userId === id && (
                    <>
                        <Link className={`${style["linkBtn"]} ${selectedButton === 'Criar Serviço' ? style["selected"] : ''}`} to="" onClick={() => { setSelectedButton('Criar Serviço'); setIsModalOpen(true); }}>
                            <button>Criar Serviço</button>
                        </Link>

                        {isModalOpen && (
                            <div className={style.modalBackground} onClick={() => setIsModalOpen(false)}>
                                <div className={style.modalContent} onClick={e => e.stopPropagation()}>
                                    <h3>Criar Serviço</h3>
                                    <form onSubmit={handleSubmit}>
                                        <label className={style.modalCategoria}>
                                            Categoria do Serviço:
                                            <InputField
                                                type="select"
                                                className={style["modalCategoriaInput"]}
                                                placeholder="Categoria"
                                                value={serviceCategory}
                                                onChange={e => setServiceCategory(e.target.value)}
                                                options={[
                                                    { value: "Refrigeração", label: "Refrigeração" },
                                                    { value: "Moda", label: "Moda" },
                                                    { value: "Tecnologia", label: "Tecnologia" },
                                                    { value: "Domestico", label: "Doméstico" },
                                                ]}
                                            />
                                        </label>
                                        <label className={style.modalTituloServico}>
                                            Título do Serviço:
                                            <input
                                                type="text"
                                                className={style["modalTituloInput"]}
                                                placeholder="Título"
                                                value={serviceTitle}
                                                onChange={e => setServiceTitle(e.target.value)}
                                            />
                                        </label>
                                        <label className={style.modalDescricao}>
                                            Descrição:
                                            <textarea value={serviceDescription} onChange={e => setServiceDescription(e.target.value)} />
                                        </label>
                                        <input type="submit" id={style["btnCriarServico"]} value="Criar Serviço" />
                                    </form>
                                </div>
                            </div>
                        )}
                    </>
                )}


            </section>

            <div id="Publicacoes"></div>
            <Publicacoes isOwner={userType === 'empresa' && userId === id} />

            <div id="Comentarios"></div>
            <Comentarios />

            <ToastContainer />
        </>
    );
};

export default Perfil;
